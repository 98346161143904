<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        a) Assuming you have equal amounts of each of the substances at the same temperature, rank
        each of the compounds in the following set from the highest down to the lowest absolute
        entropy.
      </p>
      <drag-to-rank-input
        v-model="inputs.input1"
        :items="items1Shuffled"
        class="mb-10 pl-8"
        style="width: 500px"
        prepend-text="Highest"
        append-text="Lowest"
      />

      <p class="mb-n3">
        b) Of the chemical species listed below, which has the <b>highest</b> absolute entropy?
      </p>

      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing" class="mb-0 pl-6">
        <v-radio
          v-for="item in items2Shuffled"
          :key="'pt-2-' + item.value"
          class="my-1"
          :value="item.value"
        >
          <template #label>
            <stemble-latex :content="item.text" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import DragToRankInput from '../inputs/DragToRankInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'Question293V2',
  components: {
    DragToRankInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: null,
      },
    };
  },
  computed: {
    versionNumberA() {
      return this.taskState.getValueBySymbol('versionNumberA').content;
    },
    versionNumberB() {
      return this.taskState.getValueBySymbol('versionNumberB').content;
    },
    items1() {
      if (this.versionNumberA.value === 1) {
        return ['$\\ce{CO2(g)}$', '$\\ce{O2(g)}$', '$\\ce{C2(s)}$', '$\\ce{CO(g)}$'];
      } else if (this.versionNumberA.value === 2) {
        return ['$\\ce{NO2(g)}$', '$\\ce{O2(g)}$', '$\\ce{NO(g)}$', '$\\ce{N2(l)}$'];
      } else if (this.versionNumberA.value === 3) {
        return ['$\\ce{H2O2(l)}$', '$\\ce{H2O(s)}$', '$\\ce{H2O(l)}$', '$\\ce{NH2NH2(l)}$'];
      } else {
        return [];
      }
    },
    items2() {
      if (this.versionNumberB.value === 1) {
        return [
          {text: '$\\ce{Ar(g)}$', value: 'Ar'},
          {text: '$\\ce{PH3(g)}$', value: 'PH3'},
          {text: '$\\ce{CH3OCH3(g)}$', value: 'CH3OCH3'},
          {text: '$\\ce{C2H5OH(l)}$', value: 'C2H5OH'},
        ];
      } else if (this.versionNumberB.value === 2) {
        return [
          {text: '$\\ce{Ne(g)}$', value: 'Ne'},
          {text: '$\\ce{CO2(g)}$', value: 'CO2'},
          {text: '$\\ce{CH2(OH)2(l)}$', value: 'CH2(OH)2'},
          {text: '$\\ce{CF3COCl(g)}$', value: 'CF3COCl'},
        ];
      } else {
        return [];
      }
    },
    seed() {
      return this.$inertia.getSeed();
    },
    items1Shuffled() {
      return seededShuffle(this.items1, this.seed);
    },
    items2Shuffled() {
      return seededShuffle(this.items2, this.seed);
    },
  },
};
</script>
